import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Icon } from '@ebth/design-system';

import Layout from '../components/layout';
import SEO from '../components/seo';
import InputField from '../components/InputField';

import few_once_image from '../images/Afew-once.svg';
import few_frequently_image from '../images/Afew-frequently_1.svg';
import alot_once_image from '../images/Alot-once.svg';
import alot_frequently_image from '../images/Alot-frequently.svg';

import './get-started.scss';
import { CookieContext } from '../context/CookieProvider';

import imgJewelryWatches from '../images/leadgen_jewelry-watches1.jpg';
import imgFurniture from '../images/leadgen_furniture2.jpg';
import imgMusicalInstrument from '../images/leadgen_musicalinstrument2.jpg';
import imgArt from '../images/leadgen_art2.jpg';
import imgAutomobile from '../images/leadgen_automobile.jpg';
import imgCollectibles from '../images/leadgen_collectibles_2.jpg';
import imgFashion from '../images/leadgen_fashion-accessories.jpg';
import imgElectronics from '../images/leadgen_electronics.jpg';
import imgDecor from '../images/leadgen_decor.jpg';
import imgEverythingElse from '../images/leadgen_everything-else1.jpg';

const hearOptions = [
  {
    id: 2151,
    name: "Web Search"
  },
  {
    id: 2152,
    name: "Press / Media"
  },
  {
    id: 2153,
    name: "Social Media"
  },
  {
    id: 2154,
    name: "Online Ad"
  },
  {
    id: 2155,
    name: "Radio"
  },
  {
    id: 2156,
    name: "Trade Show / Local Event"
  },
  {
    id: 2157,
    name: "Word of Mouth"
  },
  {
    id: 2158,
    name: "Referral"
  },
  {
    id: 2160,
    name: "HGTV"
  },
  {
    id: 2159,
    name: "Other"
  },
]

const categories = [
  {
    id: 2126,
    name: 'Jewelry & Watches',
    imgUrl: imgJewelryWatches,
  },
  {
    id: 2127,
    name: 'Furniture',
    imgUrl: imgFurniture,
  },
  {
    id: 2128,
    name: 'Musical Instruments',
    imgUrl: imgMusicalInstrument,
  },
  {
    id: 2129,
    name: 'Art',
    imgUrl: imgArt,
  },
  {
    id: 2130,
    name: 'Automotive',
    imgUrl: imgAutomobile,
  },
  {
    id: 2131,
    name: 'Collectibles',
    imgUrl: imgCollectibles,
  },
  {
    id: 2132,
    name: 'Fashion & Accessories',
    imgUrl: imgFashion,
  },
  {
    id: 2133,
    name: 'Electronics & Computers',
    imgUrl: imgElectronics,
  },
  {
    id: 2134,
    name: 'Décor',
    imgUrl: imgDecor,
  },
  {
    id: 2135,
    name: 'Other',
    imgUrl: imgEverythingElse,
  },
];

const hiddenInputs = {
  tfa_1923: 'utm_source',
  tfa_1925: 'utm_medium',
  tfa_1927: 'utm_campaign',
  tfa_1990: 'utm_term',
  tfa_1992: 'utm_content',
  tfa_2098: 'anonid',
  tfa_2099: 'userid',
  tfa_2101: 'deviceid',
  tfa_2119: 'gclid',
};

const renderCategoryOption = (cat) => (
  <label key={cat.id} className="category-box item-type" id={`tfa_${cat.id}-L`} htmlFor={`tfa_${cat.id}`}>
    <img className="category-box__img" src={cat.imgUrl} alt={cat.name} />
    <div className="category-box__input">
      <span><strong>{cat.name}</strong></span>
      <input type="checkbox" name={`tfa_${cat.id}`} id={`tfa_${cat.id}`} value={`tfa_${cat.id}`} />
      <div className="category-checkbox" />
    </div>
  </label>
);

const renderHearOption = (option) => (
  <option key={option.id} value={`tfa_${option.id}`} id={`tfa_${option.id}`}>
    {option.name}
  </option>
);

const renderHiddenInputs = (params) => (
  <div className="hidden-inputs">
    {Object.keys(hiddenInputs).map((key) => <input type="hidden" name={key} id={key} key={`hidden-input-${key}`} value={params[hiddenInputs[key]] || ''} />)}
  </div>
);


const renderPhotoInputs = (photos) => (
  <div className="photos">
    {photos.map((photo, index) => (
      <div className="u-my4 wForm photo-width" id="tfa_2142-D" key={`photo-input-${index}`}>
        <div className="oneField" id="tfa_2142-D" data-repeatlabel="Add Photo">
          <input type="file" id={`tfa_2142[${index}]`} name={`tfa_2142[${index}]`} size="" title="Add photo" className="photo" />
        </div>
      </div>
    ))}
  </div>
);

const GetStartedPage = () => {
  const [formValues, setFormValues] = useState({});
  const [photos, setPhotos] = useState([{}]);

  // Context not available when gatsby building for some reason, empty object fallback prevents build failure
  const context = useContext(CookieContext) || {};

  const handleChange = (e) => setFormValues({ ...formValues, [e.target.id]: e.target.value });
  const getFormValue = (field) => formValues[field] || '';

  const handleZipUpdate = (e) => {
    const { target: { value } } = e;
    // postal-code has a 5 character max
    if (value.toString().length < 6) {
      handleChange(e);
    }
  };

  // Max photos available for upload: 10 (FormAssembly)
  const canAddPhoto = () => photos.length < 10;

  const addPhoto = () => {
    if (canAddPhoto()) {
      setPhotos([...photos, {}]);
    }
  };

  const onFormSubmit = (e) => {
    // Prevent form submission if no item types are selected
    if (!Array.prototype.filter.call(document.getElementsByClassName('item-type'), (element) => element.control.checked).length > 0 ) {
      e.preventDefault();
      document.querySelector('#types-of-items').scrollIntoView();
      alert('Must select one or more type of item.');
    }
  }

  return (
    <Layout pageName="Seller Lead Form" hideActions>
      <SEO title="Sell with EBTH" />
      <Helmet>
        <script>{`
          /*
          *  Handles measuring time spent on form, need to add element to capture time and migrate to and effect
          */

          document.addEventListener("DOMContentLoaded", function(){
              const FORM_TIME_START = Math.floor((new Date).getTime()/1000);
              let formElement = document.getElementById("tfa_0");
              if (null === formElement) {
                  formElement = document.getElementById("0");
              }
              let appendJsTimerElement = function(){
                  let formTimeDiff = Math.floor((new Date).getTime()/1000) - FORM_TIME_START;
                  let cumulatedTimeElement = document.getElementById("tfa_dbCumulatedTime");
                  if (null !== cumulatedTimeElement) {
                      let cumulatedTime = parseInt(cumulatedTimeElement.value);
                      if (null !== cumulatedTime && cumulatedTime > 0) {
                          formTimeDiff += cumulatedTime;
                      }
                  }
                  let jsTimeInput = document.createElement("input");
                  jsTimeInput.setAttribute("type", "hidden");
                  jsTimeInput.setAttribute("value", formTimeDiff.toString());
                  jsTimeInput.setAttribute("name", "tfa_dbElapsedJsTime");
                  jsTimeInput.setAttribute("id", "tfa_dbElapsedJsTime");
                  jsTimeInput.setAttribute("autocomplete", "off");
                  if (null !== formElement) {
                      formElement.appendChild(jsTimeInput);
                  }
              };
              if (null !== formElement) {
                  if(formElement.addEventListener){
                      formElement.addEventListener('submit', appendJsTimerElement, false);
                  } else if(formElement.attachEvent){
                      formElement.attachEvent('onsubmit', appendJsTimerElement);
                  }
              }
          });
      `}</script>
        <link href="https://www.tfaforms.com/dist/form-builder/5.0.0/wforms-layout.css?v=5110" rel="stylesheet" type="text/css" />
        <link href="https://www.tfaforms.com/themes/get/17258" rel="stylesheet" type="text/css" />
        <link href="https://www.tfaforms.com/dist/form-builder/5.0.0/wforms-jsonly.css?v=570-1" rel="alternate stylesheet" title="This stylesheet activated by javascript" type="text/css" />
        <script type="text/javascript" src="https://www.tfaforms.com/wForms/3.11/js/wforms.js?v=5110"></script>
        <script type="text/javascript" src="https://www.tfaforms.com/wForms/3.11/js/localization-en_US.js?v=5110"></script>
      </Helmet>
      <div className="get-started-header">
        <div className="get-started-header__text-container">
          <h1 className="get-started-header__text">Have things to sell? We’ll do all the work.</h1>
        </div>
      </div>
      <form
        method="post"
        action="https://www.tfaforms.com/responses/processor"
        className="hintsBelow labelsAbove get-started"
        id="4808951"
        encType="multipart/form-data"
        onSubmit={onFormSubmit}
      >
        <noscript>
          <div style={{ color: 'red' }}>
            For full functionality of this page it is necessary to <a href="http://www.enable-javascript.com/">enable Javascript.</a>
          </div>
        </noscript>

        {
          /*
          //////////////////////////////////
          NAME AND ZIPCODE
          //////////////////////////////////
          */
        }
        <section className="u-bg-gold-100">
          <div className="u-center u-text-center u-py8 sell-layout about-you">
            <h2 className="u-mb2">Tell us about you</h2>
            <p className="u-mb4 required-mark">We will identify the services available to you.</p>
            <div className="l-flex-rows-3">
              <div id="tfa_21-D" className="icon-input name-input-group">
                <Icon icon="account-circle" size="50" />
                <div className="input-row">
                  <InputField
                    label="First Name"
                    floatedLabel
                    name="tfa_21"
                    autocomplete="given-name"
                    onChange={handleChange}
                    value={getFormValue('tfa_21')}
                    id="tfa_21"
                    required
                  />
                  <InputField
                    label="Last Name"
                    floatedLabel
                    name="tfa_2141"
                    autocomplete="family-name"
                    onChange={handleChange}
                    value={getFormValue('tfa_2141')}
                    id="tfa_2141"
                    required
                  />
                </div>
              </div>
              <div id="tfa_2069-D" className="icon-input">
                <Icon icon="location" size="50" />
                <InputField
                  type="number"
                  label="Zipcode"
                  floatedLabel
                  name="tfa_2069"
                  autocomplete="postal-code"
                  onChange={handleZipUpdate}
                  value={getFormValue('tfa_2069')}
                  id="tfa_2069"
                  required
                />
              </div>
            </div>
          </div>
        </section>
        {
          /*
          //////////////////////////////////
          ITEM COUNT SELECTION
          //////////////////////////////////
          */
        }
        <section className="u-center u-text-center u-py8 sell-layout" id="item-quantity">
          <h2 className="u-mb2">How many items are&nbsp;you&nbsp;selling?</h2>
          <p className="u-mb4 required-mark">Tell us how many pieces & how often.</p>
          <div className="inputWrapper">
            <div className="item-count-width">
              <div className="" id="tfa_2120" role="group" aria-required="true" aria-labelledby="tfa_2120-L">
                <div className="l-flex-rows-4 category-box-row item-list">

                  <label className="category-box" htmlFor="tfa_2121">
                    <img className="category-box__img" src={few_once_image} alt="" />
                    <div className="category-box__input">
                      <span>A few items <strong>once</strong></span>
                      <input type="radio" name="tfa_2120" id="tfa_2121" value="tfa_2121" required />
                      <div className="radio-input" />
                    </div>
                  </label>

                  <label className="category-box" htmlFor="tfa_2122">
                    <img className="category-box__img" src={alot_once_image} alt="" />
                    <div className="category-box__input">
                      <span>A lot of items <strong>once</strong></span>
                      <input type="radio" name="tfa_2120" id="tfa_2122" value="tfa_2122" required />
                      <div className="radio-input" />
                    </div>
                  </label>

                  <label className="category-box" htmlFor="tfa_2123">
                    <img className="category-box__img" src={few_frequently_image} alt="" />
                    <div className="category-box__input">
                      <span>A few items <strong>frequently</strong></span>
                      <input type="radio" name="tfa_2120" id="tfa_2123" value="tfa_2123" required />
                      <div className="radio-input" />
                    </div>
                  </label>

                  <label className="category-box" htmlFor="tfa_2124">
                    <img className="category-box__img" src={alot_frequently_image} alt="" />
                    <div className="category-box__input">
                      <span>A lot of items <strong> frequently</strong></span>
                      <input type="radio" name="tfa_2120" id="tfa_2124" value="tfa_2124" required />
                      <div className="radio-input" />
                    </div>
                  </label>

                </div>
              </div>
            </div>
          </div>
        </section>


        {
          /*
          //////////////////////////////////
          CATEGORY SELECTION
          //////////////////////////////////
          */
        }
        <section id="types-of-items" className="u-center u-text-center u-py8 sell-layout">
          <h2 className="u-mb2">What types of items do you have?</h2>
          <p className="u-mb4 required-mark">Select the categories that apply.</p>

          <div className="inputWrapper">
            <span id="tfa_2125" className="choices required">
              <div className="category-width">
                <div className="u-mb3 l-flex-rows-5 item-list" id="tfa_2125-D" role="group" aria-required="true" aria-labelledby="tfa_2125-L">

                  {categories.map((cat) => renderCategoryOption(cat))}

                </div>
              </div>
            </span>
          </div>
        </section>

        {
          /*
          //////////////////////////////////
          PERSONAL OR BUSINESS COLLECTION
          //////////////////////////////////
          */
        }
        <section id="services-partnership" className="u-center u-text-center u-py8 sell-layout">
          <h2 className="u-mb2">Home Services or Consignment Partnership?</h2>
          <p className="u-mb4 required-mark">This helps us find the right specialist to guide your needs.</p>

          <div className="inputWrapper">
            <span id="tfa_2164" className="choices required">
              <div className="" id="tfa_2164-D" role="group" aria-required="true" aria-labelledby="tfa_2164-L">
                  <div className="flex l-flex-rows-2">

                    <label className="category-box" htmlFor="tfa_2165">
                      <Icon icon="home" size="80" className="category-box__img" />
                      <div className="category-box__input">
                        <span>I am managing personal items from a <strong>home</strong> or an <strong>estate</strong></span>
                        <input type="radio" name="tfa_2164" id="tfa_2165" value="tfa_2165" />
                        <div className="radio-input" />
                      </div>
                    </label>

                    <label className="category-box" htmlFor="tfa_2166">
                      <Icon icon="pick-up" size="80" className="category-box__img" />
                      <div className="category-box__input">
                        <span>I am a <strong>collector</strong>, <strong>dealer</strong>, or <strong>picker</strong></span>
                        <input type="radio" name="tfa_2164" id="tfa_2166" value="tfa_2166" />
                        <div className="radio-input" />
                      </div>
                    </label>

                  </div>
                </div>
            </span>
          </div>
        </section>

        {
          /*
          //////////////////////////////////
          ADD PHOTO
          //////////////////////////////////
          */
        }
        <section className="u-center u-text-center u-py8 sell-layout">
          <h2 className="u-mb2">Send us a photo of your items</h2>
          <p className="u-mb2">We’ll accept between 1-10 photos here. If you’d like to send more photos, please wait until an EBTH team member responds to&nbsp;your&nbsp;submission.</p>
          {renderPhotoInputs(photos)}
          {canAddPhoto() ? (
            <Button onClick={addPhoto} buttonStyle="primary" buttonSize="l" className="u-py1 u-px2 u-mx2 u-my2">Add Photo</Button>
          ) : (
            <p><em><small>Max photos: <strong>10</strong></small></em></p>
          )}
          <div className="u-mt2">
            <p><em><small>Photos must be in JPG, PNG, GIF, BMP, or TIF format and <strong>2MB or less</strong> in size</small></em></p>
          </div>
        </section>

        {
          /*
          //////////////////////////////////
          CONTACT Method
          //////////////////////////////////
          */
        }
        <section id="contact-method" className="u-bg-gold-100">
          <div className="u-center u-text-center u-pt8 u-pb4 sell-layout">
            <h2 className="u-mb2">How can we get in touch with you?</h2>
            <p className="u-mb4 required-mark">We’ll use your information only to get in touch about your items, it will never be shared.</p>
            <div className="methods">
              <div className="item-box box-border vertmargin10lr u-mb2">
                <h3><Icon icon="email" /> E-Mail Me</h3>
                <InputField
                  type="email"
                  label="Email Address"
                  floatedLabel
                  name="tfa_1873"
                  autocomplete="email"
                  onChange={handleChange}
                  value={getFormValue('tfa_1873')}
                  id="tfa_1873"
                  required
                />
              </div>
              <div className="item-box box-border vertmargin10lr u-mb2">
                <h3><Icon icon="phone" /> Call Me</h3>
                <InputField
                  type="tel"
                  label="Phone Number"
                  floatedLabel
                  name="tfa_192"
                  autocomplete="tel"
                  onChange={handleChange}
                  value={getFormValue('tfa_192')}
                  id="tfa_192"
                  required
                />
              </div>
            </div>
          </div>
        </section>

        {
          /*
          //////////////////////////////////
          HOW DID YOU HEAR ABOUT US?
          //////////////////////////////////
          */
        }
        <section className="u-bg-gold-100">
          <div className="u-center u-text-center u-pb8 u-pt0 sell-layout">
            <div className="item-box hear box-border vertmargin10lr u-mb2">
              <h3>How Did You Hear About Us?</h3>
              <select
                name="tfa_2150"
                onChange={handleChange}
                defaultValue=""
                id="tfa_2150"
              >
                <option value="">Please Select...</option>
                {hearOptions.map((option) => renderHearOption(option))}
              </select>
            </div>
          </div>
        </section>
        {
          /*
          //////////////////////////////////
          OTHER INFO
          //////////////////////////////////
          */
        }
        <section id="other-info" className="u-center u-py8 u-bg-gold-100">
          <div className="sell-layout">
            <h2 className="u-mb4 u-text-center">Is there anything else we should know about you or your&nbsp;items?</h2>
            <div className="input-container">
              <textarea className="input short" id="tfa_12" name="tfa_12" placeholder="Tell us more." title="comments" />
              <div className="u-center u-text-center">
                <Button type="submit" buttonStyle="primary" buttonSize="xl" id="submit-button" className="u-py2 u-px8 u-mx2 u-my2">Let's Get Started!</Button>
              </div>
            </div>
          </div>
        </section>


        {renderHiddenInputs(context)}

        <input type="hidden" value="4808951" name="tfa_dbFormId" id="tfa_dbFormId" />
        <input type="hidden" value="" name="tfa_dbResponseId" id="tfa_dbResponseId" />
        <input type="hidden" value="61259d524a50b175d7db8b17d6549e24" name="tfa_dbControl" id="tfa_dbControl" />
        <input type="hidden" value="3" name="tfa_dbVersionId" id="tfa_dbVersionId" />
        <input type="hidden" value="" name="tfa_switchedoff" id="tfa_switchedoff" />

        {
         /*
          * The following form field is required to be able to submit multiple files, unable to find documentation detailing this.
          * Found by inspecting Form Assembly submissions. Assumed the Value needs to be the length of additional fields
          */
        }
        <input type="hidden" value={photos.length - 1} name="tfa_2142-D[0]-RC" id="tfa_2142-D[0]-RC" />
      </form>
    </Layout>
  );
};

export default GetStartedPage;
